<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableNew'
import { downloadAction, getAction } from '@/command/netTool'
import { hotelMixin } from '@/mixins/orderProcessing/hotelMixin'
import { eatDrinkMixin } from '@/mixins/orderProcessing/eatDrinkMixin'
import { farmingExperienceMixin } from '@/mixins/orderProcessing/farmingExperienceMixin'
import { scenicAreasTicketsMixin } from '@/mixins/orderProcessing/scenicAreasTickets'
import { localProductsMixin } from '@/mixins/orderProcessing/localProductsMixin'
import { Tooltip } from 'ant-design-vue'

export default {
  mixins: [hotelMixin, eatDrinkMixin, farmingExperienceMixin, scenicAreasTicketsMixin, localProductsMixin],
  methods: {
    getTimePickerKeys() {
      const { dateTypeQuery } = this.$route.query
      switch (dateTypeQuery) {
        case 0:
          return ['dateGe', 'dateLe']
        case 1:
          return ['dateMonthGe', 'dateMonthLe']
        case 2:
          return ['dateYearStr', 'dateYearStr']
      }
    },
    getHeader() {
      return [
        {
          type: 'rangePicker',
          name: '日期',
          keys: ['dateGe', 'dateLe'],
        },
        {
          type: 'select',
          name: '订单状态',
          key: 'status',
          typeData: [
            {
              name: '待使用',
              value: 1,
            },
            {
              name: '待评价',
              value: 4,
            },
            {
              name: '已完成',
              value: 5,
            },
            {
              name: '已关闭',
              value: 6,
            },
          ],
        },
       
      ]
    },
    getColumns() {
      const { fromType,businessName } = this.$route.query
      return [
        {
          title: fromType == 13 ? '出售房间' : '出售门票',
          dataIndex: 'productName',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                {records.productPic ? <img src={records.productPic.split(',')[0]} /> : ''}
                <Tooltip title={text}>
                  <div>{text}</div>
                </Tooltip>
              </div>
            )
          },
        },
        {
          // title: fromType == 13 ? '酒店名称' : '景点名称',
          title: '商户名称',
          dataIndex: 'businessName',
          customRender:()=>{
             return businessName
          }
        },
        {
          title: '购买人',
          dataIndex: 'concatName',
          customRender: (_, records) => {
            return <div>{records.concatName || records.buyName}</div>
          },
        },
        {
          title: '手机号',
          dataIndex: 'concatPhone',
          customRender: (_, records) => {
            return <div>{records.concatPhone || records.buyPhone}</div>
          },
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
        },
        {
          title: '数量',
          dataIndex: 'productQuantity',
          customRender: (_, records) => {
            const num = records.orderItemList.reduce((a, b) => b.productQuantity + a, 0)
            return num
          },
        },
        {
          title: '订单金额',
          dataIndex: 'totalAmount',
          customRender: (text, records) => {
            let newText = text
            if (records.refundAmount) {
              newText = newText + `(退款:${records.refundAmount})`
            }
            return newText
          },
        },
        {
          title: '核销时间',
          dataIndex: 'usedTime',
          customRender: (text, records) => {
            return text || '-'
          },
        },
        {
          title: '核销数量',
          dataIndex: 'productQuantityCompleted',
        },
        {
          title: '核销金额',
          dataIndex: 'totalAmountCompleted',
         },
        {
          title: '退款数量',
          dataIndex: 'refundCount',
        },
        {
          title: '退款金额',
          dataIndex: 'refundAmount',
        },
        {
          dataIndex: 'salesJsAmount',
          title: '结算金额',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                <span>{(records.salesJsAmount || 0).toFixed(2)}</span>
              </div>
            )
          },
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          customRender: (data) => {
            const state =
              data === '102' ? '待确认' : ['待支付', '待使用', '待发货', '待签收', '待评价', '已完成', '已关闭'][data]
            return <div>{state}</div>
          },
        },
        {
          dataIndex: 'a1',
          title: '操作',
          align: 'center',
          customRender: (text, records) => {
            return (
              <a
                onClick={() => {
                  const fromType = records.fromType
                  switch (fromType) {
                    case '1':
                      this.onSelectRowLocalProducts(records, true)
                      break
                    case '2':
                      this.onRowSelectEatDrink(records.id)
                      break
                    case '4':
                      this.onRowSelectFarmingExperience(records.id)
                      break
                    case '12':
                      this.onRowSelectScenicAreasTickets(records, null, true)
                      break
                    case '13':
                    case '14':
                      this.onRowSelectHotel(records, true)
                      break
                  }
                }}
              >
                详情
              </a>
            )
          },
        },
      ]
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const { dateLe, dateGe, dateMonthLe, dateMonthGe, dateYearStr, dateTypeQuery, fromType,status } =
              this.$refs.table.params
            const { businessId } = this.$route.query
 
            downloadAction(
              `/api/reportForm/exportOrderTotal?fromType=${fromType || ''}&dateTypeQuery=${
                dateTypeQuery || ''
              }&dateMonthLe=${dateMonthLe || ''}&dateMonthGe=${dateMonthGe || ''}&dateLe=${dateLe || ''}&dateGe=${
                dateGe || ''
              }&dateYearStr=${dateYearStr || ''}&businessId=${businessId}&status=${status || ''}`,
              '商户报表详情.xlsx'
            )
          },
        },
      ]
    },
  },
  render() {
    const { dateLe, dateGe, dateMonthLe, dateMonthGe, dateYearStr, dateTypeQuery, fromType, businessId } =
      this.$route.query
    return (
      <TemplateHeaderTableNew
        ref={'table'}
        url={`/reportForm/getOrderPage`}
        pageParams={{ dateTypeQuery, dateLe, dateGe, dateMonthLe, dateMonthGe, dateYearStr, fromType, businessId }}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        rightButton={this.getRightButton()}
        button={[]}
        // resetDefaultData={{
        //   fromType,
        // }}
        filterRightButtonKey={['refresh', 'setting']}
      />
    )
  },
}
</script>
